@font-face {
    font-family: "DS-DIGI";
    src: url(../font/DS-DIGI.ttf);
}

/*:root {*/
/*    --vh: 100%;*/
/*}*/
html {
}
body {
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    font-family: "DS-DIGI";
    /*background-color: var(--C-allBG-color);*/
}

/* 첫번째 영역 나눔 ----------------------------------------------------*/

body {
    display: flex;
}
